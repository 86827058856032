/*-------------------------------
  kv
-------------------------------*/
.kv_item {
	background: $clr_red;
	margin-top: 50px;

	@include pc {
		margin-top: 80px;
	}

	&_img {
		padding: 20px 0 30px 0;

		@include pc {
			max-width: 1065px;
			margin: 0 auto;
		}
	}
}

/*-------------------------------
 Download ダウンロード
-------------------------------*/
.download {
	.sec_ttl {
		@include pc {
			margin-bottom: 50px;
		}
	}

	h3 {
		margin: 25px 0;

		@include pc {
			margin: 45px 0 30px 0;
		}
	}
}

.sec_dl {
	&_item {
		display: flex;
		align-items: center;

		a {
			display: block;

			@include pc {
				width: 50%;
			}

			text-align: left;

			&:nth-of-type(1) {
				margin-right: 20px;
				text-align: right;
			}
		}
	}

	&_logo_item {
		@include pc {
			display: flex;
			align-items: center;
			justify-content: center;

			.sec_dl_logo {
				width: 50%;
				text-align: right;
				margin-right: 3em;
			}

			h3 {
				width: 50%;
				text-align: left;
			}
		}
	}
}

/*-------------------------------
 Concept コンセプト
-------------------------------*/
.concept {
	background: $clr_red;

	.sec_ttl {
		position: relative;

		&::before {
			content: "";
			display: block;
			width: 32px;
			height: 30px;
			background: url(../../assets/img/top/heart_yel.png) right / contain no-repeat;
			position: absolute;
			top: 15%;
			right: -30%;
			transform: translate(-30%, -15%);
		}
	}

	h3 {
		font-family: $font_biz_udp;
		@include fz(29);
		line-height: 1.5;
		color: #fff;

		@include pc {
			@include fz(35);
		}
	}

	p {
		@include fz(15);
		font-weight: bold;
		color: #fff;

		@include pc {
			@include fz(22);
		}
	}

	.sec_item_list {
		margin-bottom: 30px;

		p {
			@include pc {
				text-align: left;
			}
		}

		.icon {
			position: relative;

			@include pc {
				text-indent: -1.8em;
				margin-left: 1.8em;
			}

			&::before {
				content: "";
				display: inline-block;
				width: 15px;
				height: 15px;
				background: url(../../assets/img/top/concept_icon.png) center / contain no-repeat;
				vertical-align: middle;
				margin: -3px 5px 0 0px;

				@include pc {
					width: 30px;
					height: 30px;
					margin: -8px 10px 0 0;
				}
			}
		}

		&_fx {
			@include pc {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}

		&_lt {
			@include pc {
				width: calc(700 / 1000 * 100%);
				margin-right: 1em;
			}
		}

		&_rt {
			@include pc {
				width: calc(300 / 1000 * 100%);
			}
		}
	}
}

/*-------------------------------
 Service サービス
-------------------------------*/
.service {
	.sec_item {
		margin-bottom: 70px;

		&:nth-last-of-type(1) {
			margin-bottom: 0;
		}

		@include pc {
			display: flex;
			align-items: flex-start;
			justify-content: center;
			align-items: center;
			margin-bottom: 5em;
		}

		figure {
			@include pc {
				width: calc(450 / 1000 * 100%);
				margin-left: 3em;
			}
		}

		&_num {
			text-align: center;

			span {
				display: inline-block;
				font-family: $font_montserrat;
				@include fz(55);
				font-weight: bold;
				line-height: 1.5;
				color: $clr_red;
				position: relative;

				@include pc {
					@include fz(60);
				}

				&::before {
					content: "";
					display: block;
					width: 32px;
					height: 30px;
					background: url(../../assets/img/top/heart_yel.png) center / contain no-repeat;
					position: absolute;
					top: 50%;
					left: -50%;
					transform: translate(-50%, -50%);
				}
			}
		}

		&:nth-of-type(2) {
			.sec_item_num span {
				&::before {
					width: 30px;
					height: 34px;
					background: url(../../assets/img/top/heart_grn.png) center / contain no-repeat;
				}
			}
		}

		&:nth-of-type(3) {
			.sec_item_num span {
				&::before {
					left: 100%;
					margin: -15px 0 0 20px;
					transform: rotate(135deg);
				}
			}
		}

		h3 {
			@include fz(30);
			font-weight: bold;
			line-height: 1.4;
			margin-bottom: 30px;

			@include pc {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				min-height: 125px;
			}
		}

		&_txt {
			font-weight: bold;
			margin: 20px 0 40px 0;

			@include pc {
				@include fz(22);
				margin: 30px 0;
			}

			span {
				color: $clr_red;
				font-weight: bold;

				@include pc {
					@include fz(22);
				}
			}
		}

		&_img_btm {
			@include pc {
				width: calc(400 / 1000 * 100%);
				margin: 0 auto;
			}
		}
	}
}

/*-------------------------------
完全無料！今すぐダウンロード！
-------------------------------*/
.dl_area {
	display: none;
	background: #fff;
	box-shadow: 0 7px 2px -2px rgba(234, 232, 232, 0.2) inset;

	.sec_inner {
		padding: 10px 0;
	}

	.sec_item {
		&_ttl {
			text-align: center;
			margin-bottom: 5px;

			@include pc {
				margin-left: 35px;
			}
		}

		a {
			display: block;
			width: 50%
		}
	}
}

.dl_btn_fixed {
	display: block;
	position: fixed;
	bottom: 0;
	right: 0;
	transition: all 0.7s;
	z-index: 2;

	&.none {
		display: none;
	}

	@include sp {
		bottom: 0;
	}
}

/*-------------------------------
How to use
-------------------------------*/
.howto {
	background: $clr_red;

	.sec_inner {
		&::before {
			content: "";
			display: block;
			width: 46px;
			height: 40px;
			background: url(../../assets/img/top/circle_icon.svg) right / contain no-repeat;
			position: absolute;
			top: 3%;
			right: 0;

			@include pc {
				top: 5%;
				right: 20%;
			}
		}
	}

	.sec_ttl {
		&::before {
			content: "";
			display: block;
			width: 23px;
			height: 23px;
			background: url(../../assets/img/top/heart_grn.png) right / contain no-repeat;
			position: absolute;
			top: 20%;
			left: 60%;
			transform: rotate(50deg);

			@include pc {
				width: 40px;
				height: 40px;
				top: 15%;
				left: 70%;
			}
		}

		&_s {
			position: relative;

			&::after {
				content: "";
				display: block;
				width: 30px;
				height: 30px;
				background: url(../../assets/img/top/heart_yel.png) right / contain no-repeat;
				position: absolute;
				bottom: -10%;
				right: -75%;
				transform: rotate(90deg);
			}
		}
	}

	.howto_slider {
		width: 100vw;
		margin: 0 calc(50% - 50vw);
		padding-top: 10px;

		&_item {
			text-align: center;
			// padding: 0 15% 0 0 !important;
			padding: 0 5% !important;

			@include pc {
				padding: 0 5% !important;
			}
		}

		&_img {
			display: inline-block;
			position: relative;

			@include sp {
				padding: 0 10%;
			}

			&::before {
				content: "";
				display: block;
				position: absolute;
				top: 5px;
				left: 25px;
				right: 0;
				bottom: 0;
				background-color: rgba(0, 0, 0, 0.5);
				border-radius: 20px;
				width: 77%;
				z-index: 2;

				@include pc {
					width: 96%;
					border-radius: 30px;
					left: 5px;
				}
			}

			img {
				width: 100%;
			}
		}

		.slider_img {
			&::before {
				display: none;
			}

			&::before {
				@include pc {
					display: block;
					width: 97%;
					height: 98%;
				}
			}
		}

		.slick-track {
			display: flex;
		}

		.slick-slide {
			height: auto !important;
		}

		.slick-center {
			.howto_slider_img {
				&::before {
					display: none;
				}
			}
		}

		.prev_arrow {
			display: none !important;
		}

		.slide-arrow {
			width: 15px;
			height: 16px;
			background: url(../../assets/img/top/howto_arrow.svg) center / contain no-repeat;
			position: absolute;
			top: 35%;
			transform: translateY(-50%);
			z-index: 999999;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			@include pc {
				width: 30px;
				height: 31px;
			}
		}

		.prev-arrow {
			display: none !important;

			@include pc {
				display: block !important;
				left: 35.5vw;
				transform: rotate(180deg);
				margin-top: -15px;
			}

			@include tab {
				left: 25vw;
			}
		}

		.next-arrow {
			right: 14.5vw;
			padding-right: 5px;

			@include pc {
				right: 35.5vw;
			}

			@include tab {
				right: 30vw;
			}
		}

		&_item {
			&_btm {

				p,
				span {
					color: #fff;
					font-weight: bold;
					text-align: center;
				}

				&_cat {
					font-family: $font_montserrat;
					@include fz(28);
					line-height: 1.5;

					@include pc {
						@include fz(35);
					}
				}

				&_txt {
					@include fz(13);

					@include pc {
						@include fz(18);
					}

					&_s {
						display: block;
						@include fz(16);

						@include pc {
							@include fz(22);
						}
					}
				}
			}
		}
	}

	@include sp {
		.slider_btm {
			.slider_img {
				padding: 0 5%;

				img {
					padding: 13% 0;
				}
			}
		}
	}
}

.txt_icon {
	width: 12px;
	height: 12px;
	display: inline-block;
	margin: 0 2px;

	@include pc {
		width: 20px;
		height: 20px;
	}
}

/*-------------------------------
case 利用する時はこんな感じで！
-------------------------------*/
.case {
	.sec_cat {
		display: inline-block;
		position: relative;

		&::after {
			content: "";
			display: block;
			width: 28px;
			height: 32px;
			background: url(../../assets/img/top/heart_grn.png) right / contain no-repeat;
			position: absolute;
			top: 50%;
			right: -20%;
			margin-top: -15px;
			transform: rotate(180deg);

			@include pc {
				right: -10%;
			}
		}
	}

	.case_slider {
		width: 100vw;
		margin: 0 calc(50% - 50vw);
		padding-top: 10px;

		&_item {
			text-align: center;
			padding: 0 10% !important;
			position: relative;

			@include sp {
				margin-top: 3em;
			}

			&_txt {
				width: 100%;
				position: absolute;
				top: -40px;
				left: 0;
				z-index: 2;

				span {
					display: inline-block;
					@include fz(12);
					font-weight: bold;
					text-align: center;
					padding: 10px;
					background: #fff;
					border-radius: 8px;
					box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

					@include pc {
						@include fz(16);
					}

					&::after {
						content: "";
						position: absolute;
						width: 0;
						height: 0;
						display: block;
						border-style: solid;
						border-color: transparent #fff #fff transparent;
						border-width: 13px;
						bottom: -12px;
						left: 45%;
						border-radius: 0 0 10px 0;
						transform: scaleX(0.5) rotate(45deg);
					}
				}
			}
		}

		&_img {
			display: block;
			position: relative;

			&::before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(0, 0, 0, 0.5);
				border-radius: 50%;
				width: 100%;
				height: 100%;
				z-index: 1;
			}
		}

		.slick-center {
			.case_slider_img {
				&::before {
					display: none;
				}
			}
		}

		.slide-arrow {
			width: 15px;
			height: 16px;
			background: url(../../assets/img/top/case_arrow.svg) center / contain no-repeat;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			z-index: 999999;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			@include pc {
				width: 30px;
				height: 31px;
			}
		}

		.prev-arrow {
			display: none !important;

			@include pc {
				display: block !important;
				left: 34.5vw;
				transform: rotate(180deg);
				margin-top: -15px;
			}

			@include tab {
				left: 28.5vw;
			}
		}

		.next-arrow {
			right: 13.5vw;
			padding-right: 5px;

			@include pc {
				right: 34.5vw;
			}

			@include tab {
				right: 28.5vw;
			}
		}

	}
}

/*-------------------------------
FAQ よくある質問
-------------------------------*/
.faq {
	background: $clr_red;

	.sec_inner {
		&::before {
			content: "";
			display: block;
			width: 16px;
			height: 14px;
			background: url(../../assets/img/top/heart_yel.png) right / contain no-repeat;
			position: absolute;
			top: 6%;
			right: 60%;
			transform: rotate(45deg);
		}
	}

	.sec_ttl {
		&::before {
			content: "";
			display: block;
			width: 40px;
			height: 35px;
			background: url(../../assets/img/top/circle_icon.svg) right / contain no-repeat;
			position: absolute;
			top: 3em;
			right: 2em;
			transform: rotate(-80deg);
		}

		&::after {
			bottom: 15%;
		}
	}

	.faq_item {
		padding: 20px 0;
		border-bottom: 2px solid #fff;

		@include pc {
			padding: 15px 0;
			text-align: left;
		}

		&_ttl,
		&_txt {
			@include fz(16);
			font-weight: bold;
			color: #fff;
			line-height: 1.7;
			padding-left: 35px;
			position: relative;

			@include pc {
				@include fz(20);
				padding-left: 40px;
			}

			&:before {
				display: block;
				font-family: $font_montserrat;
				@include fz(16);
				font-weight: bold;
				color: $clr_red;
				width: 20px;
				height: 20px;
				line-height: 20px;
				text-align: center;
				background: #fff;
				border-radius: 5px;
				position: absolute;
				top: 0;
				left: 0;

				@include pc {
					@include fz(20);
					width: 30px;
					height: 30px;
					line-height: 30px;
				}
			}
		}

		&_ttl {
			padding-right: 30px;

			&:before {
				content: "Q";
				margin-top: 3px;
			}

			&:after {
				content: "";
				display: block;
				width: 20px;
				height: 20px;
				background: url(../../assets/img/top/faq_arrow.svg) right / contain no-repeat;
				position: absolute;
				top: 50%;
				right: 0;
				margin-top: -10px;

				@include pc {
					width: 25px;
					height: 25px;
					margin-top: -15px;
				}
			}

			&.close {
				&::after {
					transform: rotate(180deg);
				}
			}

			&:hover {
				cursor: pointer;
			}

			.txt_icon {
				margin: -30px 2px 0 2px;
				vertical-align: middle;

				@include pc {
					margin: -15px 5px 0;
				}
			}
		}

		&_txt {
			display: none;
			padding-top: 15px;
			margin-top: 10px;
			border-top: 2px dotted #fff;

			@include pc {
				border-top: 4px dotted #fff;
			}

			&:before {
				content: "A";
				margin-top: 20px;
			}
		}
	}
}