html {
  scroll-behavior: smooth;
}

body {
  font-family: $font_biz_udp;
  color: $clr_base;
  background: #fff;
  position: relative;
}

p,
span,
li {
  font-family: $font_biz_udp;
  @include fz(15);
  font-weight: bold;
  line-height: 1.7;
  color: $clr_base;
  word-break: normal;
}

/*-------------------------------
  footer固定
-------------------------------*/
.container {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
  position: relative;
  z-index: 2;
}

/*-------------------------------
  contents
-------------------------------*/
.sec_contents {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.sec_inner {
  max-width: 768px;
  width: 90%;
  margin: 0 auto;
  padding: 70px 0;
  position: relative;

  @include pc {
    max-width: 1000px;
    padding: 95px 0;
    text-align: center;
  }
}

.logo {
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 70px;
    height: 68px;
    background: url(../../assets/img/top/logo_img.png) right / contain no-repeat;
    position: absolute;
    top: 10em;
    right: 0;
    transform: translate(0, -50%);

    @include pc {
      width: 100px;
      height: 100px;
      top: 9em;
      right: 10%;
    }
  }
}

.sec_ttl {
  display: inline-block;
  font-family: $font_montserrat;
  @include fz(45);
  font-weight: bold;
  line-height: 0.8;
  color: #fff;

  @include pc {
    @include fz(60);
  }

  &.red {
    color: $clr_red;
  }

  &_s {
    display: inline-block;
    @include fz(17);
    font-weight: bold;
    border-bottom: 2px solid #fff;
    color: #fff;

    @include pc {
      @include fz(22);
    }

    &.red {
      color: $clr_red;
      border-bottom: 2px solid $clr_red;
    }
  }
}

.sec_fx_js {
  display: flex;
  justify-content: space-between;
}

.sec_fx_js_ac {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


/*-------------------------------
  page-top
-------------------------------*/
#page-top {
  position: fixed;
  z-index: 2;
  bottom: 10em;
  right: 1em;

  @include sp {
    bottom: 7em;
  }

  a {
    display: block;
    background: #000;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    border-radius: 50%;
    position: relative;

    @include sp {
      width: 40px;
      height: 40px;
      line-height: 40px;
    }

    &:before {
      content: "";
      width: 15px;
      height: 15px;
      display: block;
      border-top: solid 2px #ffffff;
      border-right: solid 2px #ffffff;
      transform: rotate(-45deg);
      position: absolute;
      top: 5px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transform: rotate(-45deg);

      @include sp {
        width: 10px;
        height: 10px;
        top: 3px;
      }
    }
  }
}