/*-------------------------------
  Footer
-------------------------------*/
.footer {
	&_item {
		background: #999999;
		&_inner {
			max-width: 1000px;
			margin: 0 auto;
			padding: 30px 20px;
		}
		&_logo {
			display: flex;
			align-items: center;
			@include fz(14);
			color: #fff;
			margin-bottom: 20px;
			@include pc {
				justify-content: center;
			}
			span {
				margin-left: 20px;
			}
		}
		nav {
			ul {
				display: flex;
				flex-wrap: wrap;
				@include pc {
					justify-content: center;
				}
				li {
					a {
						@include fz(13);
						color: #fff;
						padding: 0 10px;
						border-right: 1px solid #fff;
						position: relative;
						@include pc {
							@include fz(15);
						}
						&:hover {
							opacity: 0.8;
						}
					}
					&:nth-last-of-type(1) {
						a {
							border: none;
						}
					}
				}
			}
		}
		&_btm {
			background: #666666;
			.footer_copy {
				display: block;
				@include fz(12);
				color: #fff;
				text-align: center;
				padding: 10px 0;
			}
		}
	}
}
