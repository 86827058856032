/*-------------------------------
  font
-------------------------------*/
@mixin fz($size, $base: 16) {
  font-size: $size + px;
  font-size: ($size / $base) + rem;
}
@mixin txt_in {
  text-indent: -1em;
  margin-left: 1em;
}
/*-------------------------------
 break-point
-------------------------------*/
@mixin pc_b {
  @media screen and (min-width: 1700px) {
    @content;
  }
}
@mixin tab {
  @media screen and (min-width: 768px) and (max-width: 1000px) {
    @content;
  }
}
@mixin tab_s {
  @media screen and (max-width: 900px) {
    @content;
  }
}
@mixin pc {
  @media screen and (min-width: 768px) {
    @content;
  }
}
@mixin sp {
  @media screen and (max-width: 768px) {
    @content;
  }
}
@mixin sp_s {
  @media screen and (max-width: 380px) {
    @content;
  }
}
/*------------------------------
  position
-------------------------------*/
@mixin ps_50_50 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
