.mb10{margin-bottom: 10px;}
.mb20{margin-bottom: 20px;}
.mb30{margin-bottom: 30px;}
.mb40{margin-bottom: 40px;}
.mb50{margin-bottom: 50px;}
@include pc {
	.mb10{margin-bottom: 20px;}
	.mb20{margin-bottom: 40px;}
	.mb30{margin-bottom: 60px;}
	.mb40{margin-bottom: 80px;}
	.mb50{margin-bottom: 100px;}
}
.pb10{padding-bottom: 10px;}
.pb20{padding-bottom: 20px;}
.pb30{padding-bottom: 30px;}
.pb40{padding-bottom: 40px;}
.pb50{padding-bottom: 50px;}
@include pc {
	.pb10{padding-bottom: 20px;}
	.pb20{padding-bottom: 40px;}
	.pb30{padding-bottom: 60px;}
	.pb40{padding-bottom: 60px;}
	.pb50{padding-bottom: 100px;}
}
