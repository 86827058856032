/*--------------------------------------
  animation
---------------------------------------*/
//fade
@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeup {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bg {
  0% {
    opacity: 0;
    transform: scaleX(0) translateX(-5%);
  }

  30% {
    transform: scaleX(1) translateX(0);
  }

  100% {
    transform: scaleX(1) translateX(0);
  }

  30%,
  100% {
    opacity: 1;
  }
}
.fade {
  opacity: 0;
}
.anime_active {
  .fade {
    opacity: 1;
    animation: fade 1.5s ease-in-out forwards;

    &.delay_05s {
      animation-delay: 0.5s;
    }
    &.delay_1s {
      animation-delay: 1s;
    }
    &.delay_15s {
      animation-delay: 1.5s;
    }
    &.delay_2s {
      animation-delay: 2s;
    }
    &.delay_25s {
      animation-delay: 2.5s;
    }
    &.delay_3s {
      animation-delay: 3s;
    }
    &.delay_4s {
      animation-delay: 3s;
    }
  }
}
