.pc { display: block !important; }
.tab { display: none !important; }
.sp { display: none !important; }
.pc_tab { display: block !important; }
.tab_sp { display: none !important; }
@include sp {
.pc { display: none !important; }
.pc_tab { display: none !important; }
.tab { display: none !important; }
.sp { display: block !important; }
}
//--- iframe_area ---//
.iframe_area {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  
  iframe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}
//--- anchor ---//
a {
  text-decoration: none;
  cursor: pointer;
  transition: all .3s ease;
}
a:hover {
	opacity: 0.8;
  cursor: pointer;
}
