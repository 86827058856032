/*--------------------------------------
  header
---------------------------------------*/
.header {
  width: 100%;
  height: 50px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;

  @include pc {
    height: 80px;
  }
}

.header_contents {
  width: 100%;
  height: 50px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  align-items: center;

  @include pc {
    justify-content: space-between;
    height: 80px;
    padding: 0 0 0 30px;
  }

  .h_logo {
    position: fixed;
    z-index: 9;
  }
}

.header_nav_contents {
  @include sp {
    width: 100%;
    height: 100vh;
    height: 100dvh;
    background: $clr_red;
    text-align: center;
    display: none;
    position: fixed;
    top: 50px;
    left: 0;
    z-index: 999;
    overflow-y: auto;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_inner {
    @include sp {
      flex-direction: column;
      padding: 3em 1em 0 1em;
    }

    .h_nav {
      display: block;
      width: 100%;
      @include fz(18);
      margin: 0 0 1em 0;
      font-weight: bold;
      color: #fff;
      position: relative;

      @include pc {
        color: $clr_red;
        margin: 0 35px 0 0;
      }

      &:hover {
        opacity: 0.8;
        color: #000;
      }
    }
  }
}

@include sp {
  .drawer_nav_wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 0 15px;
    position: fixed;
    position: absolute;
    top: 15px;
    right: 0;
    z-index: 999;
    box-sizing: border-box;
  }

  .menu_trigger {
    display: none;
    display: inline-block;
    position: relative;
    width: 30px;
    height: 20px;
    vertical-align: middle;
    cursor: pointer;

    span {
      display: inline-block;
      box-sizing: border-box;
      position: absolute;
      left: 0;
      width: 30px;
      height: 2px;
      background: $clr_red;
      transition: all 0.5s 0.2s;

      &:nth-of-type(1) {
        top: 0;
      }

      &:nth-of-type(2) {
        top: 9px;
      }

      &:nth-of-type(3) {
        bottom: 0;
      }
    }

    &.active span {
      background: $clr_red;

      &:nth-of-type(1) {
        transform: translateY(10px) rotate(-45deg);
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        transform: translateY(-10px) rotate(45deg);
      }
    }
  }

  .active {
    overflow: hidden;

    .header_nav_contents {
      visibility: visible;
      opacity: 1;

      li {
        opacity: 1;
        transform: translateX(0);
        transition: transform 1s ease, opacity 0.9s ease;
        text-align: center;
      }
    }
  }

  .header_nav_contents {
    li {
      opacity: 0;
      transform: translateX(200px);
      transition: transform 0.6s ease, opacity 0.2s ease;

      &:nth-child(2) {
        transition-delay: 0.15s;
      }

      &:nth-child(3) {
        transition-delay: 0.3s;
      }

      &:nth-child(4) {
        transition-delay: 0.45s;
      }

      &:nth-child(5) {
        transition-delay: 0.65s;
      }
    }

    .header_nav_sp {
      li {
        &:nth-child(1) {
          transition-delay: 1s;
        }

        &:nth-child(2) {
          transition-delay: 1.15s;
        }

        &:nth-child(3) {
          transition-delay: 1.25s;
        }

        &:nth-child(4) {
          transition-delay: 1.35s;
        }

        &:nth-child(5) {
          transition-delay: 1.45s;
        }
      }
    }
  }

  .header_nav_sp {
    &_btm {
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;

      span {
        display: block;
        margin-left: 5px;
      }
    }

    li,
    a {
      @include fz(14);
      font-weight: 500;
    }

    a {
      color: #fff;
      margin-bottom: 15px;
    }

    .sec_dl_item {
      justify-content: center;

    }
  }
}