/*-------------------------------
  プライバシーポリシー/ドートマッチ利用規約/特定商取引法に基づく表記
-------------------------------*/
.pages_contents {
	.sec_ttl {
		display: block;
		text-align: center;
	}

	p,
	li {
		@include fz(15);
		text-align: left;
		font-weight: 500;

		@include pc {
			@include fz(16);
			line-height: 1.9;
		}
	}

	.sec_item {
		text-align: left;
		margin-bottom: 20px;

		@include pc {
			margin-bottom: 30px;
		}

		&_ttl {
			display: block;
			@include fz(17);
			font-weight: bold;
			margin-bottom: 5px;

			@include pc {
				@include fz(20);
			}
		}

		&_btm {
			p {
				text-align: right;
			}

			a {
				color: #000;
			}
		}

		&_col {
			@include pc {
				margin-bottom: 60px;
			}
		}

		ol {
			counter-reset: count 0;

			li {
				position: relative;

				&:before {
					counter-increment: count 1;
				}
			}
		}

		&_num {
			li {
				text-indent: -1.3em;
				padding-left: 1.3em;

				&:before {
					content: counter(count) ". ";
				}
			}
		}

		&_par {
			margin-top: 20px;

			li {
				text-indent: -2em;
				padding-left: 2em;

				&:before {
					content: "（"counter(count) "）";
					margin-right: 0.3em;
				}
			}
		}

		&_circle {
			li {
				padding: 0 0 0 0.3em;
				text-indent: 0;
				display: flex;
				align-items: center;

				&:before {
					content: counter(count);
					display: flex;
					justify-content: center;
					align-items: center;
					height: 17px;
					width: 17px;
					line-height: 17px;
					@include fz(13);
					margin-right: 0.3em;
					border: 1px solid;
					border-radius: 50%;
				}
			}
		}

		.txt_rt {
			text-align: right;
		}

		// 特定商取引法に基づく表記
		&_list {
			display: flex;
			flex-wrap: wrap;
			border: 1px solid $clr_red;

			@include sp {
				flex-direction: column;
			}

			dt,
			dd {
				padding: 2em;
				line-height: 1.8;

				@include tab_s {
					padding: 1em;
				}

				&:nth-last-of-type(1) {
					border-bottom: none;
				}
			}

			dt {
				width: 30%;
				color: #fff;
				background: $clr_red;
				border-bottom: 1px solid #fff;
				display: flex;
				align-items: center;
				justify-content: center;

				@include sp {
					width: 100%;
				}
			}

			dd {
				width: 70%;
				text-align: left;
				border-bottom: 1px solid $clr_red;

				@include sp {
					width: 100%;
				}

				a {
					display: block;
					color: #000;
				}
			}
		}
	}

	#page-top {
		bottom: 2em;
	}
}