@charset "UTF-8";


/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
@import url("https://fonts.googleapis.com/css2?family=BIZ+UDPGothic:wght@400;700&family=Montserrat:wght@400;500;700&display=swap");
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

li {
  list-style: none;
}

body {
  line-height: 1;
}

html * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: bottom;
}

img {
  -webkit-backface-visibility: hidden;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

/*-------------------------------
  font
-------------------------------*/
/*-------------------------------
 break-point
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
.pc {
  display: block !important;
}

.tab {
  display: none !important;
}

.sp {
  display: none !important;
}

.pc_tab {
  display: block !important;
}

.tab_sp {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .pc {
    display: none !important;
  }
  .pc_tab {
    display: none !important;
  }
  .tab {
    display: none !important;
  }
  .sp {
    display: block !important;
  }
}

.iframe_area {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
}

.iframe_area iframe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: all .3s ease;
}

a:hover {
  opacity: 0.8;
  cursor: pointer;
}

/*--------------------------------------
  header
---------------------------------------*/
.header {
  width: 100%;
  height: 50px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

@media screen and (min-width: 768px) {
  .header {
    height: 80px;
  }
}

.header_contents {
  width: 100%;
  height: 50px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .header_contents {
    justify-content: space-between;
    height: 80px;
    padding: 0 0 0 30px;
  }
}

.header_contents .h_logo {
  position: fixed;
  z-index: 9;
}

@media screen and (max-width: 768px) {
  .header_nav_contents {
    width: 100%;
    height: 100vh;
    height: 100dvh;
    background: #e60012;
    text-align: center;
    display: none;
    position: fixed;
    top: 50px;
    left: 0;
    z-index: 999;
    overflow-y: auto;
  }
}

.header_nav_contents ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .header_nav_contents_inner {
    flex-direction: column;
    padding: 3em 1em 0 1em;
  }
}

.header_nav_contents_inner .h_nav {
  display: block;
  width: 100%;
  font-size: 18px;
  font-size: 1.125rem;
  margin: 0 0 1em 0;
  font-weight: bold;
  color: #fff;
  position: relative;
}

@media screen and (min-width: 768px) {
  .header_nav_contents_inner .h_nav {
    color: #e60012;
    margin: 0 35px 0 0;
  }
}

.header_nav_contents_inner .h_nav:hover {
  opacity: 0.8;
  color: #000;
}

@media screen and (max-width: 768px) {
  .drawer_nav_wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 0 15px;
    position: fixed;
    position: absolute;
    top: 15px;
    right: 0;
    z-index: 999;
    box-sizing: border-box;
  }
  .menu_trigger {
    display: none;
    display: inline-block;
    position: relative;
    width: 30px;
    height: 20px;
    vertical-align: middle;
    cursor: pointer;
  }
  .menu_trigger span {
    display: inline-block;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    width: 30px;
    height: 2px;
    background: #e60012;
    transition: all 0.5s 0.2s;
  }
  .menu_trigger span:nth-of-type(1) {
    top: 0;
  }
  .menu_trigger span:nth-of-type(2) {
    top: 9px;
  }
  .menu_trigger span:nth-of-type(3) {
    bottom: 0;
  }
  .menu_trigger.active span {
    background: #e60012;
  }
  .menu_trigger.active span:nth-of-type(1) {
    transform: translateY(10px) rotate(-45deg);
  }
  .menu_trigger.active span:nth-of-type(2) {
    opacity: 0;
  }
  .menu_trigger.active span:nth-of-type(3) {
    transform: translateY(-10px) rotate(45deg);
  }
  .active {
    overflow: hidden;
  }
  .active .header_nav_contents {
    visibility: visible;
    opacity: 1;
  }
  .active .header_nav_contents li {
    opacity: 1;
    transform: translateX(0);
    transition: transform 1s ease, opacity 0.9s ease;
    text-align: center;
  }
  .header_nav_contents li {
    opacity: 0;
    transform: translateX(200px);
    transition: transform 0.6s ease, opacity 0.2s ease;
  }
  .header_nav_contents li:nth-child(2) {
    transition-delay: 0.15s;
  }
  .header_nav_contents li:nth-child(3) {
    transition-delay: 0.3s;
  }
  .header_nav_contents li:nth-child(4) {
    transition-delay: 0.45s;
  }
  .header_nav_contents li:nth-child(5) {
    transition-delay: 0.65s;
  }
  .header_nav_contents .header_nav_sp li:nth-child(1) {
    transition-delay: 1s;
  }
  .header_nav_contents .header_nav_sp li:nth-child(2) {
    transition-delay: 1.15s;
  }
  .header_nav_contents .header_nav_sp li:nth-child(3) {
    transition-delay: 1.25s;
  }
  .header_nav_contents .header_nav_sp li:nth-child(4) {
    transition-delay: 1.35s;
  }
  .header_nav_contents .header_nav_sp li:nth-child(5) {
    transition-delay: 1.45s;
  }
  .header_nav_sp_btm {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
  }
  .header_nav_sp_btm span {
    display: block;
    margin-left: 5px;
  }
  .header_nav_sp li,
  .header_nav_sp a {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 500;
  }
  .header_nav_sp a {
    color: #fff;
    margin-bottom: 15px;
  }
  .header_nav_sp .sec_dl_item {
    justify-content: center;
  }
}

/*-------------------------------
  Footer
-------------------------------*/
.footer_item {
  background: #999999;
}

.footer_item_inner {
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px 20px;
}

.footer_item_logo {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-size: 0.875rem;
  color: #fff;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .footer_item_logo {
    justify-content: center;
  }
}

.footer_item_logo span {
  margin-left: 20px;
}

.footer_item nav ul {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 768px) {
  .footer_item nav ul {
    justify-content: center;
  }
}

.footer_item nav ul li a {
  font-size: 13px;
  font-size: 0.8125rem;
  color: #fff;
  padding: 0 10px;
  border-right: 1px solid #fff;
  position: relative;
}

@media screen and (min-width: 768px) {
  .footer_item nav ul li a {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

.footer_item nav ul li a:hover {
  opacity: 0.8;
}

.footer_item nav ul li:nth-last-of-type(1) a {
  border: none;
}

.footer_item_btm {
  background: #666666;
}

.footer_item_btm .footer_copy {
  display: block;
  font-size: 12px;
  font-size: 0.75rem;
  color: #fff;
  text-align: center;
  padding: 10px 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "BIZ UDPGothic", sans-serif;
  color: #000;
  background: #fff;
  position: relative;
}

p,
span,
li {
  font-family: "BIZ UDPGothic", sans-serif;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: bold;
  line-height: 1.7;
  color: #000;
  word-break: normal;
}

/*-------------------------------
  footer固定
-------------------------------*/
.container {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
  position: relative;
  z-index: 2;
}

/*-------------------------------
  contents
-------------------------------*/
.sec_contents {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.sec_inner {
  max-width: 768px;
  width: 90%;
  margin: 0 auto;
  padding: 70px 0;
  position: relative;
}

@media screen and (min-width: 768px) {
  .sec_inner {
    max-width: 1000px;
    padding: 95px 0;
    text-align: center;
  }
}

.logo {
  position: relative;
}

.logo::after {
  content: "";
  display: block;
  width: 70px;
  height: 68px;
  background: url(../../assets/img/top/logo_img.png) right/contain no-repeat;
  position: absolute;
  top: 10em;
  right: 0;
  transform: translate(0, -50%);
}

@media screen and (min-width: 768px) {
  .logo::after {
    width: 100px;
    height: 100px;
    top: 9em;
    right: 10%;
  }
}

.sec_ttl {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 45px;
  font-size: 2.8125rem;
  font-weight: bold;
  line-height: 0.8;
  color: #fff;
}

@media screen and (min-width: 768px) {
  .sec_ttl {
    font-size: 60px;
    font-size: 3.75rem;
  }
}

.sec_ttl.red {
  color: #e60012;
}

.sec_ttl_s {
  display: inline-block;
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: bold;
  border-bottom: 2px solid #fff;
  color: #fff;
}

@media screen and (min-width: 768px) {
  .sec_ttl_s {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

.sec_ttl_s.red {
  color: #e60012;
  border-bottom: 2px solid #e60012;
}

.sec_fx_js {
  display: flex;
  justify-content: space-between;
}

.sec_fx_js_ac {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*-------------------------------
  page-top
-------------------------------*/
#page-top {
  position: fixed;
  z-index: 2;
  bottom: 10em;
  right: 1em;
}

@media screen and (max-width: 768px) {
  #page-top {
    bottom: 7em;
  }
}

#page-top a {
  display: block;
  background: #000;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 50%;
  position: relative;
}

@media screen and (max-width: 768px) {
  #page-top a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

#page-top a:before {
  content: "";
  width: 15px;
  height: 15px;
  display: block;
  border-top: solid 2px #ffffff;
  border-right: solid 2px #ffffff;
  transform: rotate(-45deg);
  position: absolute;
  top: 5px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transform: rotate(-45deg);
}

@media screen and (max-width: 768px) {
  #page-top a:before {
    width: 10px;
    height: 10px;
    top: 3px;
  }
}

/*--------------------------------------
  animation
---------------------------------------*/
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeup {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bg {
  0% {
    opacity: 0;
    transform: scaleX(0) translateX(-5%);
  }
  30% {
    transform: scaleX(1) translateX(0);
  }
  100% {
    transform: scaleX(1) translateX(0);
  }
  30%,
  100% {
    opacity: 1;
  }
}

.fade {
  opacity: 0;
}

.anime_active .fade {
  opacity: 1;
  animation: fade 1.5s ease-in-out forwards;
}

.anime_active .fade.delay_05s {
  animation-delay: 0.5s;
}

.anime_active .fade.delay_1s {
  animation-delay: 1s;
}

.anime_active .fade.delay_15s {
  animation-delay: 1.5s;
}

.anime_active .fade.delay_2s {
  animation-delay: 2s;
}

.anime_active .fade.delay_25s {
  animation-delay: 2.5s;
}

.anime_active .fade.delay_3s {
  animation-delay: 3s;
}

.anime_active .fade.delay_4s {
  animation-delay: 3s;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

@media screen and (min-width: 768px) {
  .mb10 {
    margin-bottom: 20px;
  }
  .mb20 {
    margin-bottom: 40px;
  }
  .mb30 {
    margin-bottom: 60px;
  }
  .mb40 {
    margin-bottom: 80px;
  }
  .mb50 {
    margin-bottom: 100px;
  }
}

.pb10 {
  padding-bottom: 10px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb40 {
  padding-bottom: 40px;
}

.pb50 {
  padding-bottom: 50px;
}

@media screen and (min-width: 768px) {
  .pb10 {
    padding-bottom: 20px;
  }
  .pb20 {
    padding-bottom: 40px;
  }
  .pb30 {
    padding-bottom: 60px;
  }
  .pb40 {
    padding-bottom: 60px;
  }
  .pb50 {
    padding-bottom: 100px;
  }
}

/*-------------------------------
  kv
-------------------------------*/
.kv_item {
  background: #e60012;
  margin-top: 50px;
}

@media screen and (min-width: 768px) {
  .kv_item {
    margin-top: 80px;
  }
}

.kv_item_img {
  padding: 20px 0 30px 0;
}

@media screen and (min-width: 768px) {
  .kv_item_img {
    max-width: 1065px;
    margin: 0 auto;
  }
}

/*-------------------------------
 Download ダウンロード
-------------------------------*/
@media screen and (min-width: 768px) {
  .download .sec_ttl {
    margin-bottom: 50px;
  }
}

.download h3 {
  margin: 25px 0;
}

@media screen and (min-width: 768px) {
  .download h3 {
    margin: 45px 0 30px 0;
  }
}

.sec_dl_item {
  display: flex;
  align-items: center;
}

.sec_dl_item a {
  display: block;
  text-align: left;
}

@media screen and (min-width: 768px) {
  .sec_dl_item a {
    width: 50%;
  }
}

.sec_dl_item a:nth-of-type(1) {
  margin-right: 20px;
  text-align: right;
}

@media screen and (min-width: 768px) {
  .sec_dl_logo_item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sec_dl_logo_item .sec_dl_logo {
    width: 50%;
    text-align: right;
    margin-right: 3em;
  }
  .sec_dl_logo_item h3 {
    width: 50%;
    text-align: left;
  }
}

/*-------------------------------
 Concept コンセプト
-------------------------------*/
.concept {
  background: #e60012;
}

.concept .sec_ttl {
  position: relative;
}

.concept .sec_ttl::before {
  content: "";
  display: block;
  width: 32px;
  height: 30px;
  background: url(../../assets/img/top/heart_yel.png) right/contain no-repeat;
  position: absolute;
  top: 15%;
  right: -30%;
  transform: translate(-30%, -15%);
}

.concept h3 {
  font-family: "BIZ UDPGothic", sans-serif;
  font-size: 29px;
  font-size: 1.8125rem;
  line-height: 1.5;
  color: #fff;
}

@media screen and (min-width: 768px) {
  .concept h3 {
    font-size: 35px;
    font-size: 2.1875rem;
  }
}

.concept p {
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: bold;
  color: #fff;
}

@media screen and (min-width: 768px) {
  .concept p {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

.concept .sec_item_list {
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  .concept .sec_item_list p {
    text-align: left;
  }
}

.concept .sec_item_list .icon {
  position: relative;
}

@media screen and (min-width: 768px) {
  .concept .sec_item_list .icon {
    text-indent: -1.8em;
    margin-left: 1.8em;
  }
}

.concept .sec_item_list .icon::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  background: url(../../assets/img/top/concept_icon.png) center/contain no-repeat;
  vertical-align: middle;
  margin: -3px 5px 0 0px;
}

@media screen and (min-width: 768px) {
  .concept .sec_item_list .icon::before {
    width: 30px;
    height: 30px;
    margin: -8px 10px 0 0;
  }
}

@media screen and (min-width: 768px) {
  .concept .sec_item_list_fx {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (min-width: 768px) {
  .concept .sec_item_list_lt {
    width: calc(700 / 1000 * 100%);
    margin-right: 1em;
  }
}

@media screen and (min-width: 768px) {
  .concept .sec_item_list_rt {
    width: calc(300 / 1000 * 100%);
  }
}

/*-------------------------------
 Service サービス
-------------------------------*/
.service .sec_item {
  margin-bottom: 70px;
}

.service .sec_item:nth-last-of-type(1) {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .service .sec_item {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-items: center;
    margin-bottom: 5em;
  }
}

@media screen and (min-width: 768px) {
  .service .sec_item figure {
    width: calc(450 / 1000 * 100%);
    margin-left: 3em;
  }
}

.service .sec_item_num {
  text-align: center;
}

.service .sec_item_num span {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 55px;
  font-size: 3.4375rem;
  font-weight: bold;
  line-height: 1.5;
  color: #e60012;
  position: relative;
}

@media screen and (min-width: 768px) {
  .service .sec_item_num span {
    font-size: 60px;
    font-size: 3.75rem;
  }
}

.service .sec_item_num span::before {
  content: "";
  display: block;
  width: 32px;
  height: 30px;
  background: url(../../assets/img/top/heart_yel.png) center/contain no-repeat;
  position: absolute;
  top: 50%;
  left: -50%;
  transform: translate(-50%, -50%);
}

.service .sec_item:nth-of-type(2) .sec_item_num span::before {
  width: 30px;
  height: 34px;
  background: url(../../assets/img/top/heart_grn.png) center/contain no-repeat;
}

.service .sec_item:nth-of-type(3) .sec_item_num span::before {
  left: 100%;
  margin: -15px 0 0 20px;
  transform: rotate(135deg);
}

.service .sec_item h3 {
  font-size: 30px;
  font-size: 1.875rem;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  .service .sec_item h3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 125px;
  }
}

.service .sec_item_txt {
  font-weight: bold;
  margin: 20px 0 40px 0;
}

@media screen and (min-width: 768px) {
  .service .sec_item_txt {
    font-size: 22px;
    font-size: 1.375rem;
    margin: 30px 0;
  }
}

.service .sec_item_txt span {
  color: #e60012;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .service .sec_item_txt span {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

@media screen and (min-width: 768px) {
  .service .sec_item_img_btm {
    width: calc(400 / 1000 * 100%);
    margin: 0 auto;
  }
}

/*-------------------------------
完全無料！今すぐダウンロード！
-------------------------------*/
.dl_area {
  display: none;
  background: #fff;
  box-shadow: 0 7px 2px -2px rgba(234, 232, 232, 0.2) inset;
}

.dl_area .sec_inner {
  padding: 10px 0;
}

.dl_area .sec_item_ttl {
  text-align: center;
  margin-bottom: 5px;
}

@media screen and (min-width: 768px) {
  .dl_area .sec_item_ttl {
    margin-left: 35px;
  }
}

.dl_area .sec_item a {
  display: block;
  width: 50%;
}

.dl_btn_fixed {
  display: block;
  position: fixed;
  bottom: 0;
  right: 0;
  transition: all 0.7s;
  z-index: 2;
}

.dl_btn_fixed.none {
  display: none;
}

@media screen and (max-width: 768px) {
  .dl_btn_fixed {
    bottom: 0;
  }
}

/*-------------------------------
How to use
-------------------------------*/
.howto {
  background: #e60012;
}

.howto .sec_inner::before {
  content: "";
  display: block;
  width: 46px;
  height: 40px;
  background: url(../../assets/img/top/circle_icon.svg) right/contain no-repeat;
  position: absolute;
  top: 3%;
  right: 0;
}

@media screen and (min-width: 768px) {
  .howto .sec_inner::before {
    top: 5%;
    right: 20%;
  }
}

.howto .sec_ttl::before {
  content: "";
  display: block;
  width: 23px;
  height: 23px;
  background: url(../../assets/img/top/heart_grn.png) right/contain no-repeat;
  position: absolute;
  top: 20%;
  left: 60%;
  transform: rotate(50deg);
}

@media screen and (min-width: 768px) {
  .howto .sec_ttl::before {
    width: 40px;
    height: 40px;
    top: 15%;
    left: 70%;
  }
}

.howto .sec_ttl_s {
  position: relative;
}

.howto .sec_ttl_s::after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background: url(../../assets/img/top/heart_yel.png) right/contain no-repeat;
  position: absolute;
  bottom: -10%;
  right: -75%;
  transform: rotate(90deg);
}

.howto .howto_slider {
  width: 100vw;
  margin: 0 calc(50% - 50vw);
  padding-top: 10px;
}

.howto .howto_slider_item {
  text-align: center;
  padding: 0 5% !important;
}

@media screen and (min-width: 768px) {
  .howto .howto_slider_item {
    padding: 0 5% !important;
  }
}

.howto .howto_slider_img {
  display: inline-block;
  position: relative;
}

@media screen and (max-width: 768px) {
  .howto .howto_slider_img {
    padding: 0 10%;
  }
}

.howto .howto_slider_img::before {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 25px;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  width: 77%;
  z-index: 2;
}

@media screen and (min-width: 768px) {
  .howto .howto_slider_img::before {
    width: 96%;
    border-radius: 30px;
    left: 5px;
  }
}

.howto .howto_slider_img img {
  width: 100%;
}

.howto .howto_slider .slider_img::before {
  display: none;
}

@media screen and (min-width: 768px) {
  .howto .howto_slider .slider_img::before {
    display: block;
    width: 97%;
    height: 98%;
  }
}

.howto .howto_slider .slick-track {
  display: flex;
}

.howto .howto_slider .slick-slide {
  height: auto !important;
}

.howto .howto_slider .slick-center .howto_slider_img::before {
  display: none;
}

.howto .howto_slider .prev_arrow {
  display: none !important;
}

.howto .howto_slider .slide-arrow {
  width: 15px;
  height: 16px;
  background: url(../../assets/img/top/howto_arrow.svg) center/contain no-repeat;
  position: absolute;
  top: 35%;
  transform: translateY(-50%);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .howto .howto_slider .slide-arrow {
    width: 30px;
    height: 31px;
  }
}

.howto .howto_slider .prev-arrow {
  display: none !important;
}

@media screen and (min-width: 768px) {
  .howto .howto_slider .prev-arrow {
    display: block !important;
    left: 35.5vw;
    transform: rotate(180deg);
    margin-top: -15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .howto .howto_slider .prev-arrow {
    left: 25vw;
  }
}

.howto .howto_slider .next-arrow {
  right: 14.5vw;
  padding-right: 5px;
}

@media screen and (min-width: 768px) {
  .howto .howto_slider .next-arrow {
    right: 35.5vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .howto .howto_slider .next-arrow {
    right: 30vw;
  }
}

.howto .howto_slider_item_btm p,
.howto .howto_slider_item_btm span {
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.howto .howto_slider_item_btm_cat {
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .howto .howto_slider_item_btm_cat {
    font-size: 35px;
    font-size: 2.1875rem;
  }
}

.howto .howto_slider_item_btm_txt {
  font-size: 13px;
  font-size: 0.8125rem;
}

@media screen and (min-width: 768px) {
  .howto .howto_slider_item_btm_txt {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.howto .howto_slider_item_btm_txt_s {
  display: block;
  font-size: 16px;
  font-size: 1rem;
}

@media screen and (min-width: 768px) {
  .howto .howto_slider_item_btm_txt_s {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

@media screen and (max-width: 768px) {
  .howto .slider_btm .slider_img {
    padding: 0 5%;
  }
  .howto .slider_btm .slider_img img {
    padding: 13% 0;
  }
}

.txt_icon {
  width: 12px;
  height: 12px;
  display: inline-block;
  margin: 0 2px;
}

@media screen and (min-width: 768px) {
  .txt_icon {
    width: 20px;
    height: 20px;
  }
}

/*-------------------------------
case 利用する時はこんな感じで！
-------------------------------*/
.case .sec_cat {
  display: inline-block;
  position: relative;
}

.case .sec_cat::after {
  content: "";
  display: block;
  width: 28px;
  height: 32px;
  background: url(../../assets/img/top/heart_grn.png) right/contain no-repeat;
  position: absolute;
  top: 50%;
  right: -20%;
  margin-top: -15px;
  transform: rotate(180deg);
}

@media screen and (min-width: 768px) {
  .case .sec_cat::after {
    right: -10%;
  }
}

.case .case_slider {
  width: 100vw;
  margin: 0 calc(50% - 50vw);
  padding-top: 10px;
}

.case .case_slider_item {
  text-align: center;
  padding: 0 10% !important;
  position: relative;
}

@media screen and (max-width: 768px) {
  .case .case_slider_item {
    margin-top: 3em;
  }
}

.case .case_slider_item_txt {
  width: 100%;
  position: absolute;
  top: -40px;
  left: 0;
  z-index: 2;
}

.case .case_slider_item_txt span {
  display: inline-block;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 768px) {
  .case .case_slider_item_txt span {
    font-size: 16px;
    font-size: 1rem;
  }
}

.case .case_slider_item_txt span::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-color: transparent #fff #fff transparent;
  border-width: 13px;
  bottom: -12px;
  left: 45%;
  border-radius: 0 0 10px 0;
  transform: scaleX(0.5) rotate(45deg);
}

.case .case_slider_img {
  display: block;
  position: relative;
}

.case .case_slider_img::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.case .case_slider .slick-center .case_slider_img::before {
  display: none;
}

.case .case_slider .slide-arrow {
  width: 15px;
  height: 16px;
  background: url(../../assets/img/top/case_arrow.svg) center/contain no-repeat;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .case .case_slider .slide-arrow {
    width: 30px;
    height: 31px;
  }
}

.case .case_slider .prev-arrow {
  display: none !important;
}

@media screen and (min-width: 768px) {
  .case .case_slider .prev-arrow {
    display: block !important;
    left: 34.5vw;
    transform: rotate(180deg);
    margin-top: -15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .case .case_slider .prev-arrow {
    left: 28.5vw;
  }
}

.case .case_slider .next-arrow {
  right: 13.5vw;
  padding-right: 5px;
}

@media screen and (min-width: 768px) {
  .case .case_slider .next-arrow {
    right: 34.5vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .case .case_slider .next-arrow {
    right: 28.5vw;
  }
}

/*-------------------------------
FAQ よくある質問
-------------------------------*/
.faq {
  background: #e60012;
}

.faq .sec_inner::before {
  content: "";
  display: block;
  width: 16px;
  height: 14px;
  background: url(../../assets/img/top/heart_yel.png) right/contain no-repeat;
  position: absolute;
  top: 6%;
  right: 60%;
  transform: rotate(45deg);
}

.faq .sec_ttl::before {
  content: "";
  display: block;
  width: 40px;
  height: 35px;
  background: url(../../assets/img/top/circle_icon.svg) right/contain no-repeat;
  position: absolute;
  top: 3em;
  right: 2em;
  transform: rotate(-80deg);
}

.faq .sec_ttl::after {
  bottom: 15%;
}

.faq .faq_item {
  padding: 20px 0;
  border-bottom: 2px solid #fff;
}

@media screen and (min-width: 768px) {
  .faq .faq_item {
    padding: 15px 0;
    text-align: left;
  }
}

.faq .faq_item_ttl, .faq .faq_item_txt {
  font-size: 16px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  line-height: 1.7;
  padding-left: 35px;
  position: relative;
}

@media screen and (min-width: 768px) {
  .faq .faq_item_ttl, .faq .faq_item_txt {
    font-size: 20px;
    font-size: 1.25rem;
    padding-left: 40px;
  }
}

.faq .faq_item_ttl:before, .faq .faq_item_txt:before {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-size: 1rem;
  font-weight: bold;
  color: #e60012;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: #fff;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 768px) {
  .faq .faq_item_ttl:before, .faq .faq_item_txt:before {
    font-size: 20px;
    font-size: 1.25rem;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

.faq .faq_item_ttl {
  padding-right: 30px;
}

.faq .faq_item_ttl:before {
  content: "Q";
  margin-top: 3px;
}

.faq .faq_item_ttl:after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: url(../../assets/img/top/faq_arrow.svg) right/contain no-repeat;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
}

@media screen and (min-width: 768px) {
  .faq .faq_item_ttl:after {
    width: 25px;
    height: 25px;
    margin-top: -15px;
  }
}

.faq .faq_item_ttl.close::after {
  transform: rotate(180deg);
}

.faq .faq_item_ttl:hover {
  cursor: pointer;
}

.faq .faq_item_ttl .txt_icon {
  margin: -30px 2px 0 2px;
  vertical-align: middle;
}

@media screen and (min-width: 768px) {
  .faq .faq_item_ttl .txt_icon {
    margin: -15px 5px 0;
  }
}

.faq .faq_item_txt {
  display: none;
  padding-top: 15px;
  margin-top: 10px;
  border-top: 2px dotted #fff;
}

@media screen and (min-width: 768px) {
  .faq .faq_item_txt {
    border-top: 4px dotted #fff;
  }
}

.faq .faq_item_txt:before {
  content: "A";
  margin-top: 20px;
}

/*-------------------------------
  プライバシーポリシー/ドートマッチ利用規約/特定商取引法に基づく表記
-------------------------------*/
.pages_contents .sec_ttl {
  display: block;
  text-align: center;
}

.pages_contents p,
.pages_contents li {
  font-size: 15px;
  font-size: 0.9375rem;
  text-align: left;
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .pages_contents p,
  .pages_contents li {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.9;
  }
}

.pages_contents .sec_item {
  text-align: left;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .pages_contents .sec_item {
    margin-bottom: 30px;
  }
}

.pages_contents .sec_item_ttl {
  display: block;
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: bold;
  margin-bottom: 5px;
}

@media screen and (min-width: 768px) {
  .pages_contents .sec_item_ttl {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.pages_contents .sec_item_btm p {
  text-align: right;
}

.pages_contents .sec_item_btm a {
  color: #000;
}

@media screen and (min-width: 768px) {
  .pages_contents .sec_item_col {
    margin-bottom: 60px;
  }
}

.pages_contents .sec_item ol {
  counter-reset: count 0;
}

.pages_contents .sec_item ol li {
  position: relative;
}

.pages_contents .sec_item ol li:before {
  counter-increment: count 1;
}

.pages_contents .sec_item_num li {
  text-indent: -1.3em;
  padding-left: 1.3em;
}

.pages_contents .sec_item_num li:before {
  content: counter(count) ". ";
}

.pages_contents .sec_item_par {
  margin-top: 20px;
}

.pages_contents .sec_item_par li {
  text-indent: -2em;
  padding-left: 2em;
}

.pages_contents .sec_item_par li:before {
  content: "（" counter(count) "）";
  margin-right: 0.3em;
}

.pages_contents .sec_item_circle li {
  padding: 0 0 0 0.3em;
  text-indent: 0;
  display: flex;
  align-items: center;
}

.pages_contents .sec_item_circle li:before {
  content: counter(count);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17px;
  width: 17px;
  line-height: 17px;
  font-size: 13px;
  font-size: 0.8125rem;
  margin-right: 0.3em;
  border: 1px solid;
  border-radius: 50%;
}

.pages_contents .sec_item .txt_rt {
  text-align: right;
}

.pages_contents .sec_item_list {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #e60012;
}

@media screen and (max-width: 768px) {
  .pages_contents .sec_item_list {
    flex-direction: column;
  }
}

.pages_contents .sec_item_list dt,
.pages_contents .sec_item_list dd {
  padding: 2em;
  line-height: 1.8;
}

@media screen and (max-width: 900px) {
  .pages_contents .sec_item_list dt,
  .pages_contents .sec_item_list dd {
    padding: 1em;
  }
}

.pages_contents .sec_item_list dt:nth-last-of-type(1),
.pages_contents .sec_item_list dd:nth-last-of-type(1) {
  border-bottom: none;
}

.pages_contents .sec_item_list dt {
  width: 30%;
  color: #fff;
  background: #e60012;
  border-bottom: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .pages_contents .sec_item_list dt {
    width: 100%;
  }
}

.pages_contents .sec_item_list dd {
  width: 70%;
  text-align: left;
  border-bottom: 1px solid #e60012;
}

@media screen and (max-width: 768px) {
  .pages_contents .sec_item_list dd {
    width: 100%;
  }
}

.pages_contents .sec_item_list dd a {
  display: block;
  color: #000;
}

.pages_contents #page-top {
  bottom: 2em;
}
